export const footerLinks = {
  copyrightOwner: 'Year On',
  facebookUrl: '',
  flickrUrl: '',
  instagramUrl: '',
  linkedInUrl: '',
  mediumUrl: '',
  privacyPolicyUrl: 'https://www.smashcut.com/privacy',
  termsOfServiceUrl: 'https://www.smashcut.com/terms',
  twitterUrl: '',
  vimeoUrl: '',
  youtubeUrl: ''
};

export const getInTouch = `If you have questions, you can get in touch
      with a student advisor by emailing help@smashcut.com or by clicking the
      "Help Chat" button in the header of any Smashcut webpage.`;

export const helpCenterUrl = 'https://intercom.help/smashcut';

export const highlightColor = '#8900e1';

export const auth0Logo = '/assets/themes/yearon/yearon_logo.png';

export const showDisciplines = false;

export const showProgramStartDate = false;

export const useScreenplayForPdfUploads = true;

export const hideCrews = true;

export const showLms = true;

export const topNavLinks = {
  theme: 'yearon',
  blog: {
    blogComponent: false,
    blogUrl: 'https://medium.com/smashcut-film'
  },
  helpCenter: {
    helpCenterKeyMentor: 'help center',
    helpCenterTitleMentor: 'Help Center',
    helpCenterUrlMentor: 'https://intercom.help/smashcut',
    helpCenterKey: 'help center',
    helpCenterTitle: 'Help Center',
    helpCenterUrl: 'https://intercom.help/smashcut'
  },
  availability: {
    availabilityComponent: true
  }
};

export const requiredAccountDetailsFields = {};

export const preStartModules = {};

export const strings = {
  'Assigned Students': 'Assigned Fellows',
  Assignments: 'Activities',
  Status: 'Job Title',
  Student: 'Fellow',
  Students: 'Fellows',
  Syllabus: 'Workshops'
};
