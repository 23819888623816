'use strict';

import baseConfig from './base';

const awsApi = 'https://yearon.api.smashcut.com';
const awsWss = 'wss://hhyltzjtbd.execute-api.eu-west-1.amazonaws.com/yearon';

let config = {
  apolloUri: `${awsApi}/graphql`,
  appEnv: 'aws-yo',
  baseUrl: 'https://yearon.smashcut.com',
  env: 'prod',
  logrocket: 'px1cem/yo-smashcut-web',
  logRocketEnabled: true,
  opentokApiKey: '47015604',
  publicSiteUrl: 'https://www.smashcut.com',
  pushMessagingPublicVapidKey:
    'BIzF8_yTVHtDoW4s-rIEDpuDQ8FArFuBq-BRlyrEUxdjFQ9qv6XY_6osGaYa7SRtALR6L_Apt3Dj8VcgBlnRw0s',
  sentry:
    'https://c245c4c53a6d4b589a4824c460298651@o82327.ingest.sentry.io/179879',
  sentryEnabled: true,
  smashcutApiUrl: awsApi,
  wssUrl: awsWss
};

let mergedConfig = Object.freeze(Object.assign({}, baseConfig, config));
// console.log('Using config: ', mergedConfig)
export default mergedConfig;
