// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeaderLogo--logo--2irpOErn{cursor:pointer;height:34px}\n", ""]);
// Exports
exports.locals = {
	"logo": "HeaderLogo--logo--2irpOErn"
};
module.exports = exports;
